export function handleProductDetailRedirect(to, from, next) {
  const urlParts = from.path.split("/");
  // Validate if the path has the minimum length to be a product detail page
  if (urlParts.length < 4) {
    return next();
  }

  const isSuperAdmin = urlParts[1] === "super-admin";
  const isShopPromotionsPath =
    urlParts.length === 4 && urlParts[3] === "promotions";
  const relevantPart = isSuperAdmin ? urlParts[4] : urlParts[3];
  const isBrandOrCategoryPath =
    relevantPart === "brands" || relevantPart === "category";
  const updateQueryAndProceed = (fromPage) => {
    const updatedQuery = { ...to.query, from: fromPage, fromPath: from.path };
    next({ path: to.path, query: updatedQuery });
  };
  function formatString(input) {
    if (!input) return "";
    return input
      .split("-")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  }
  if (isShopPromotionsPath) {
    return to.query.from && to.query.fromPath
      ? next()
      : updateQueryAndProceed(formatString(urlParts[3]));
  }

  if (isBrandOrCategoryPath) {
    return to.query.from && to.query.fromPath
      ? next()
      : updateQueryAndProceed(formatString(isSuperAdmin ? urlParts[5] : urlParts[4]));
  }

  next();
}
